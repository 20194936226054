import { FormEvent, useState } from "react";
import { useRecoilValue } from "recoil";

import { sendFeedback } from "../api_client";
import { auth } from "../firebase";
import userAtom from "../recoil/user";
import theme from "../theme";
import SubmitButton from "./SubmitButton";

export default function FeedbackWidget() {
  const user = useRecoilValue(userAtom);
  const [feedback, setFeedback] = useState("");
  const [show, setShow] = useState(false);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const authToken = await auth.currentUser?.getIdToken();
    await sendFeedback({ feedback, authToken });
    setFeedback("");
  };

  if (!user) return null;
  return (
    <div
      style={{
        position: "fixed",
        right: "5px",
        bottom: "5px",
        width: show ? "300px" : "auto",
        padding: "1rem",
        border: show ? "1px solid black" : "none",
        backgroundColor: show ? theme.colors.white : "transparent",
      }}
    >
      {show ? (
        <>
          <div style={{ textAlign: "right" }}>
            <a onClick={() => setShow(false)} href="#">
              X
            </a>
          </div>
          <h3>Feedback Form</h3>
          <form onSubmit={onSubmit}>
            <div>
              <textarea
                name="feedback"
                placeholder="Feedback"
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>
            <div>
              <SubmitButton>Submit</SubmitButton>
            </div>
          </form>
        </>
      ) : (
        <button onClick={() => setShow(true)}>Feedback</button>
      )}
    </div>
  );
}
