import * as APIContracts from "@bobzoller/bettersaid-contracts";
import { User } from "@bobzoller/bettersaid-contracts";

export class NeedsPaymentError extends Error {
  constructor() {
    super("Needs payment");
  }
}

type TransformParams = {
  authToken: string;
  content: string;
  refinements: APIContracts.Refinements;
};

type TransformResult = { content: string };

export const transform = async ({
  authToken,
  content,
  refinements,
}: TransformParams): Promise<TransformResult> => {
  const headers = new Headers();
  headers.set("content-type", "application/json");
  const body: APIContracts.TransformParams = {
    authToken,
    content,
    refinements,
  };
  const response = await fetch(`/api/v1/transform`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  switch (response.status) {
    case 200:
      break;
    case 402:
      throw new NeedsPaymentError();
    default:
      throw new Error(
        `invalid transform response: ${response.status} ${response.statusText}`
      );
  }
  const res = (await response.json()) as APIContracts.TransformResponse;
  return res;
};

type CreateSubscriptionParams = {
  authToken: string;
  name: string;
  email: string;
  paymentMethod: string;
};

type CreateSubscriptionResult = { clientSecret: string };

export const createSubscription = async ({
  authToken,
  name,
  email,
  paymentMethod,
}: CreateSubscriptionParams): Promise<CreateSubscriptionResult> => {
  const headers = new Headers();
  headers.set("content-type", "application/json");
  const body: APIContracts.CreateSubscriptionParams = {
    authToken,
    name,
    email,
    paymentMethod,
  };
  const response = await fetch(`/api/v1/createsubscription`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  if (response.status !== 200) {
    throw new Error(
      `invalid createsubscription response: ${response.status} ${response.statusText}`
    );
  }
  const res =
    (await response.json()) as APIContracts.CreateSubscriptionResponse;
  return res;
};

type CancelSubscriptionParams = {
  authToken: string;
};

export const cancelSubscription = async ({
  authToken,
}: CancelSubscriptionParams): Promise<void> => {
  const headers = new Headers();
  headers.set("content-type", "application/json");
  const body: APIContracts.CancelSubscriptionParams = {
    authToken,
  };
  const response = await fetch(`/api/v1/cancelsubscription`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  if (response.status !== 200) {
    throw new Error(
      `invalid cancelsubscription response: ${response.status} ${response.statusText}`
    );
  }
};

type SelfParams = {
  authToken: string;
};

type SelfResult = User;

export const self = async ({ authToken }: SelfParams): Promise<SelfResult> => {
  const headers = new Headers();
  headers.set("content-type", "application/json");
  const body: APIContracts.SelfParams = {
    authToken,
  };
  const response = await fetch(`/api/v1/self`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  if (response.status !== 200) {
    throw new Error(
      `invalid self response: ${response.status} ${response.statusText}`
    );
  }
  const res = (await response.json()) as APIContracts.SelfResponse;
  return res;
};

type SendFeedbackParams = {
  authToken: string;
  feedback: string;
};

export const sendFeedback = async ({
  authToken,
  feedback,
}: SendFeedbackParams): Promise<void> => {
  const headers = new Headers();
  headers.set("content-type", "application/json");
  const body: APIContracts.SendFeedbackParams = {
    authToken,
    feedback,
  };
  const response = await fetch(`/api/v1/sendfeedback`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  if (response.status !== 200) {
    throw new Error(
      `invalid sendfeedback response: ${response.status} ${response.statusText}`
    );
  }
};

type HistoryParams = {
  authToken: string;
};

type HistoryItem = {
  id: string;
  prompt: string;
  createdAt: string;
};
type HistoryResult = HistoryItem[];

export const history = async ({
  authToken,
}: HistoryParams): Promise<HistoryResult> => {
  const headers = new Headers();
  headers.set("content-type", "application/json");
  const body: APIContracts.HistoryParams = {
    authToken,
  };
  const response = await fetch(`/api/v1/history`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  if (response.status !== 200) {
    throw new Error(
      `invalid history response: ${response.status} ${response.statusText}`
    );
  }
  const res = (await response.json()) as APIContracts.HistoryResponse;
  return res;
};
