import { atom } from "recoil";

type User = {
  phoneNumber: string;
  uid: string;
};

export default atom<User | null>({
  key: "user",
  default: null,
});
