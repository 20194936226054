import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

import { withHistory } from "./recoil/history";
import { withSelf } from "./recoil/self";

export default function History() {
  const self = useRecoilValue(withSelf);

  if (self === null) {
    // not signed in or otherwise invalid auth
    return <Navigate replace to="/signin" />;
  }

  const history = useRecoilValue(withHistory);
  const refreshHistory = useRecoilRefresher_UNSTABLE(withHistory);

  // refresh history on mount
  useEffect(refreshHistory, []);

  return (
    <>
      <h2>History</h2>
      <ol>
        {history.map(({ id, prompt, createdAt }) => (
          <li key={id}>
            {createdAt}
            <br />
            {id}
            <br />
            {prompt}
          </li>
        ))}
      </ol>
    </>
  );
}
