import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useRecoilRefresher_UNSTABLE } from "recoil";

import { createSubscription } from "../api_client";
import { auth } from "../firebase";
import { withSelf } from "../recoil/self";
import SubmitButton from "./SubmitButton";

export default function CheckoutForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const refreshSelf = useRecoilRefresher_UNSTABLE(withSelf);

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  // main function
  const create = async () => {
    try {
      const authToken = await auth.currentUser?.getIdToken();

      // create a payment method
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name,
          email,
        },
      });

      const { clientSecret } = await createSubscription({
        authToken,
        name,
        email,
        paymentMethod: paymentMethod.paymentMethod.id,
      });

      const confirmPayment = await stripe.confirmCardPayment(clientSecret);

      if (confirmPayment.error) {
        alert(confirmPayment.error.message);
      } else {
        alert("Success! Check your email for the invoice.");
        refreshSelf();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "0.5rem",
        }}
      >
        <div style={{ flex: "50%" }}>
          <input
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div style={{ flex: "50%", textAlign: "right" }}>
          <input
            placeholder="Email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <CardElement className="card-element" />

      <div style={{ marginTop: "0.5rem" }}>
        <SubmitButton onClick={create} disabled={!stripe}>
          Subscribe
        </SubmitButton>
      </div>
    </div>
  );
}
