import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet } from "react-router-dom";

import FeedbackWidget from "./components/FeedbackWidget";
import IconButton from "./components/IconButton";

export default function Layout() {
  const logo = new URL("logo.png", import.meta.url);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: "5%" }}></div>
      <div style={{ flex: "90%", maxWidth: "50rem", paddingTop: "0.25rem" }}>
        <header>
          <div style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                window.open("https://www.instagram.com/bettersaid.ai/")
              }
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </IconButton>
          </div>
          <h1 style={{ textAlign: "center" }}>
            <img
              src={logo.toString()}
              alt="Better said,"
              style={{ width: "4em" }}
            />
          </h1>
        </header>
        <Outlet />
        <footer>
          <p>
            <br />
            <br />
            <br />
            <br />
            Thread TM LLC. All rights reserved.{" "}
            <a href="/assets/terms_of_use.html" target="_blank">
              Terms of Use
            </a>
            .{" "}
            <a href="/assets/privacy_policy.html" target="_blank">
              Privacy Policy
            </a>
            .
          </p>
          <FeedbackWidget />
        </footer>
      </div>
      <div style={{ flex: "5%" }}></div>
    </div>
  );
}
