import { Link, Navigate } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

import { cancelSubscription } from "./api_client";
import SubmitButton from "./components/SubmitButton";
import SubscriptionForm from "./components/SubscriptionForm";
import { auth } from "./firebase";
import { withSelf } from "./recoil/self";

export default function Account() {
  const self = useRecoilValue(withSelf);
  const refreshSelf = useRecoilRefresher_UNSTABLE(withSelf);

  if (self === null) {
    // not signed in or otherwise invalid auth
    return <Navigate replace to="/signin" />;
  }

  const onCancelSubscription = async () => {
    const authToken = await auth.currentUser?.getIdToken();
    await cancelSubscription({ authToken });
    refreshSelf();
  };

  return (
    <>
      <h2>Your Account</h2>
      <div style={{ marginBottom: "1rem" }}>
        <Link to="/signout">Sign out</Link>
      </div>
      {self.stripeSubscriptionId ? (
        <>
          <div>Your subscription #{self.stripeSubscriptionId} is active.</div>
          <div>
            <SubmitButton onClick={onCancelSubscription}>
              Cancel Subscription
            </SubmitButton>
          </div>
        </>
      ) : (
        <div style={{ maxWidth: "400px" }}>
          <div style={{ marginBottom: "0.5rem" }}>
            Subscribe now to raise your usage limits, just $6.99/mo.
          </div>
          <SubscriptionForm />
        </div>
      )}
    </>
  );
}
