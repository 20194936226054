import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { auth } from "./firebase";

export default function SignOut() {
  const [done, setDone] = useState(false);
  useEffect(() => {
    auth
      .signOut()
      .then(() => setDone(true))
      .catch(console.error);
  }, []);
  return <>{done ? <Navigate to="/" /> : null}</>;
}
