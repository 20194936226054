const theme = {
  colors: {
    black: "#000000",
    blue: "#05E9FD",
    greyDark: "#231F20",
    grey: "#9fa3a9",
    greyLight: "#e7e8e9",
    greyLighter: "#f5f5f5",
    white: "#ffffff",
  },
  fontSize: {
    small: 14,
    base: 16,
    med: 18,
    large: 26,
  },
  fontFamily: {
    regular: "Arial, Helvetica, sans-serif",
  },
};

export default theme;
