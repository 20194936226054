import React, { useEffect, useRef } from "react";
import styled, { CSSObject } from "styled-components";

type Props = {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  placeholder?: string;
  style?: CSSObject;
};

export function AutoGrowingTextArea({
  onChange,
  value,
  placeholder,
  style,
}: Props) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const replicate = () => {
    const el = textareaRef.current;
    (el.parentNode as HTMLDivElement).dataset.replicatedValue = el.value;
  };

  useEffect(replicate, [value]);

  return (
    <TextAreaWrapper>
      <TextArea
        ref={textareaRef}
        onChange={(e) => {
          replicate();
          onChange(e);
        }}
        value={value}
        placeholder={placeholder}
        onInput={replicate}
        style={style}
      />
    </TextAreaWrapper>
  );
}

// https://chriscoyier.net/2023/09/29/css-solves-auto-expanding-textareas-probably-eventually/

const TextArea = styled.textarea``;

const TextAreaWrapper = styled.div`
  display: grid;
  &::after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }
  & > textarea {
    resize: none;
    overflow: hidden;
  }
  & > textarea,
  &::after {
    width: 100%;
    min-height: 10rem;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
  }
`;
