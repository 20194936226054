import { onAuthStateChanged } from "firebase/auth";
import { PropsWithChildren, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import { auth } from "../firebase";
import userAtom from "../recoil/user";

export default function UserContext({ children }: PropsWithChildren) {
  const [loading, setLoading] = useState(true);
  const setUser = useSetRecoilState(userAtom);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user === null || user.isAnonymous) {
        setUser(null);
      } else {
        setUser({ uid: user.uid, phoneNumber: user.phoneNumber });
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  return loading ? null : <>{children}</>;
}
