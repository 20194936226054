import { logEvent as _logEvent, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import compatApp from "firebase/compat/app";

export const app = initializeApp({
  apiKey: process.env.MY_FIREBASE_WEB_API_KEY,
  authDomain: "betterspeak-8777d.firebaseapp.com",
  projectId: "betterspeak-8777d",
  storageBucket: "betterspeak-8777d.appspot.com",
  messagingSenderId: "596861145324",
  appId: "1:596861145324:web:eebe0e6d014bcad38bd5c9",
});

export const auth = getAuth(app);

const analytics = getAnalytics(app);

export function logEvent(name: string, params?: { [key: string]: any }) {
  _logEvent(analytics, name, params);
}

compatApp.initializeApp({
  apiKey: process.env.MY_FIREBASE_WEB_API_KEY,
  authDomain: "betterspeak-8777d.firebaseapp.com",
  projectId: "betterspeak-8777d",
  storageBucket: "betterspeak-8777d.appspot.com",
  messagingSenderId: "596861145324",
  appId: "1:596861145324:web:eebe0e6d014bcad38bd5c9",
});

export { compatApp };
