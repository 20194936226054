import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCopy, faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { WhatsappShareButton } from "react-share";

import IconButton from "./IconButton";

type ShareButtonsParams = {
  content?: string;
  className?: string;
};

export default ({ className, content }: ShareButtonsParams) => {
  const [copied, setCopied] = useState(false);
  const onCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  const onShare = () => {
    navigator.share({
      text: content,
    });
  };
  return (
    <div className={className}>
      {navigator.share ? (
        <IconButton onClick={onShare} style={{ marginRight: "1rem" }}>
          <FontAwesomeIcon icon={faShareFromSquare} size="3x" />
        </IconButton>
      ) : (
        <WhatsappShareButton
          title={content}
          url="https://www.bettersaid.ai/"
          style={{ marginRight: "1rem" }}
        >
          <FontAwesomeIcon icon={faWhatsapp} size="3x" />
        </WhatsappShareButton>
      )}
      <CopyToClipboard
        text={content}
        onCopy={onCopy}
        options={{ format: "text/plain" }}
      >
        <IconButton>
          <FontAwesomeIcon icon={faCopy} size="3x" />
          {copied && (
            <span style={{ color: "black", position: "absolute" }}>
              Copied.
            </span>
          )}
        </IconButton>
      </CopyToClipboard>
    </div>
  );
};
