import { PhoneAuthProvider } from "firebase/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { useEffect, useRef } from "react";

import { compatApp } from "../firebase";

export default function PhoneLogin() {
  const el = useRef(null);

  useEffect(() => {
    // using compatApp here because firebaseui is not yet compatible with v9 modular SDK
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(compatApp.auth());
    ui.start(el.current, {
      signInOptions: [
        {
          provider: PhoneAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
          recaptchaParameters: {
            size: "invisible",
          },
        },
      ],
      signInSuccessUrl: "/",
      // TODO(bobzoller): add privacyPolicyUrl
    });
  }, [el]);

  return (
    <>
      <div ref={el} />
    </>
  );
}
