import { HistoryResponse } from "@bobzoller/bettersaid-contracts";
import { selector } from "recoil";

import { history } from "../../api_client";
import { auth } from "../../firebase";

export const withHistory = selector<HistoryResponse | null>({
  key: "History",
  get: async () => {
    const authToken = await auth.currentUser?.getIdToken();
    if (!authToken) {
      return null;
    }
    return await history({ authToken });
  },
});
