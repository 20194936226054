import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { User, signInAnonymously } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { NeedsPaymentError, transform } from "./api_client";
import { AutoGrowingTextArea } from "./components/AutoGrowingTextArea";
import ShareButtons from "./components/ShareButtons";
import SubmitButton from "./components/SubmitButton";
import { auth, logEvent } from "./firebase";

export default function Home() {
  const [sentFirstTypeEvent, setSentFirstTypeEvent] = useState(false);
  const [content, setContent] = useState("");
  const [output, setOutput] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    logEvent("say_it_better_clicked");
    setOutput(null);
    setLoading(true);
    let user: User | null = auth.currentUser;
    if (user === null) {
      const credential = await signInAnonymously(auth);
      user = credential.user;
    }
    const authToken = await user.getIdToken();
    try {
      const { content: newContent } = await transform({
        authToken,
        content,
        refinements: { preset: "default", custom: "" },
      });
      setLoading(false);
      setOutput(newContent);
    } catch (error) {
      if (error instanceof NeedsPaymentError) {
        setLoading(false);
        if (user.isAnonymous) {
          alert(
            "You've used up your free credits. Please subscribe to continue."
          );
          navigate("/signin");
        } else {
          alert(
            "You've used up your credits. Please purchase more to continue."
          );
          navigate("/account");
        }
      } else {
        throw error;
      }
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.currentTarget.value);
    if (!sentFirstTypeEvent) {
      logEvent("user_typed");
      setSentFirstTypeEvent(true);
    }
  };

  return (
    <>
      <section>
        <AutoGrowingTextArea
          onChange={onChange}
          value={content}
          placeholder="Type, paste or voice-to-text the message you want to “Better” here."
          style={{ borderRadius: "1em" }}
        />
        <div style={{ textAlign: "right", marginTop: ".25rem" }}>
          <a href="#" onClick={() => setContent("")}>
            Clear
          </a>
        </div>
        <div style={{ marginTop: "1rem", textAlign: "center" }}>
          <SubmitButton type="submit" onClick={onSubmit} disabled={loading}>
            {loading && (
              <FontAwesomeIcon
                icon={faRefresh}
                spin
                style={{ marginRight: "0.5rem" }}
              />
            )}
            {loading ? "Editing..." : "Say It Better"}
          </SubmitButton>
        </div>
      </section>
      {output !== null && (
        <section style={{ marginTop: "2rem" }}>
          <p style={{ marginBottom: "0.2rem", textAlign: "center" }}>
            Here you go! You can final edit the text below.
          </p>
          <AutoGrowingTextArea
            onChange={(e) => setOutput(e.currentTarget.value)}
            value={output}
            style={{ borderRadius: "1em" }}
          />
          <StyledShareButtons content={output} />
        </section>
      )}
    </>
  );
}

const StyledShareButtons = styled(ShareButtons)`
  text-align: center;
  margin-top: 1rem;
`;
