import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Account from "./Account";
import App from "./App";
import History from "./History";
import Home from "./Home";
import Layout from "./Layout";
import SignIn from "./SignIn";
import SignOut from "./SignOut";

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        element: <Layout />,
        children: [
          { path: "/", element: <Home /> },
          { path: "/account", element: <Account /> },
          { path: "/signin", element: <SignIn /> },
          { path: "/signout", element: <SignOut /> },
          { path: "/history", element: <History /> },
        ],
      },
    ],
  },
]);

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<RouterProvider router={router} />);
