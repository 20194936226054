import { User } from "@bobzoller/bettersaid-contracts";
import { selector } from "recoil";

import { self } from "../../api_client";
import { auth } from "../../firebase";

export const withSelf = selector<User | null>({
  key: "Self",
  get: async () => {
    const authToken = await auth.currentUser?.getIdToken();
    if (!authToken) {
      return null;
    }
    return await self({ authToken });
  },
});
