import PhoneLogin from "./components/PhoneLogin";

export default function SignIn() {
  return (
    <>
      <h1>Sign In</h1>
      <PhoneLogin />
    </>
  );
}
